<template>
  <v-dialog
      v-model="isDialogVisible"
      max-width="600px"
  >
    <template #activator="{ on, attrs }"> 
        <v-btn-toggle :class="{'mr-3': !buttonText}" style="align-items: center" class="mt-4">
          <v-btn color="primary" v-on="on" v-bind="attrs">
            {{ $t(buttonText) }}
          </v-btn>
        </v-btn-toggle>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span class="headline">{{ $t('Create Product') }}</span>
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              href="https://help.sellerassistant.deals/en/articles/6694433-how-to-create-a-deal"
              target="_blank"
              icon
              v-bind="attrs"
              v-on="on"
              color="#16B1FF"
            >
              <v-icon>
                {{ icons.mdiHelpCircle }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Click to open help page") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              :disabled="loading"
              v-model="linkToAmazon"
              :rules="linkToAmazonRules"
              :label="$t('Link to Amazon')"
              required
              outlined
              dense
              hide-details="auto"
              class="mb-4"
            />
            <v-text-field
              :disabled="loading"
              v-model="supplierLink"
              :rules="supplierLinkRules"
              :background-color="!$vuetify.theme.isDark ? '#fdf5e6' : 'rgb(85, 80, 119)'"
              :label="$t('Supplier Link')"
              required
              outlined
              dense
              hide-details="auto"
              class="mb-4"
            />
            <v-row class="mb-1">
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  :disabled="loading"
                  v-model="cog"
                  :rules="cogRules"
                  :prefix="cCodeToSymbol[currencyCode]"
                  type="number"
                  :label="$t('COG')"
                  required
                  outlined
                  dense
                  hide-details="auto"
                />
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  :disabled="loading"
                  v-model="threePL"
                  :rules="thirdRules"
                  :prefix="cCodeToSymbol[currencyCode]"
                  type="number"
                  :label="$t('3PL')"
                  required
                  outlined
                  dense
                  hide-details="auto"
                />
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  :disabled="loading"
                  v-model="dealPrice"
                  :rules="priceRules"
                  prefix="$"
                  type="number"
                  :label="$t('Deal Price')"
                  required
                  outlined
                  dense
                  hide-details="auto"
                />
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="fees"
                  prefix="$"
                  type="number"
                  :label="$t('Fees')"
                  required
                  outlined
                  dense
                  disabled
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-checkbox
              class="mt-0"
              v-model="bundle"
              :label="$t('Set/Bundle')"
            />
            <v-textarea
              :disabled="loading"
              v-model="description"
              :label="$t('Public Deal Description')"
              rows="2"
              outlined
              hide-details="auto"
              class="mb-4"
            />
            <v-textarea
              :disabled="loading"
              v-model="vendorNote"
              :label="$t('Message for Buyers')"
              rows="2"
              outlined
              :background-color="!$vuetify.theme.isDark ? '#fdf5e6' : 'rgb(85, 80, 119)'"
              hide-details
              class="mb-0"
            />
            <span class="p-1 d-block mb-2 mt-2 secondary--text">{{ $t('This information will be available when the buyer will purchase the deal.') }}</span>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            outlined
            @click="isDialogVisible = false"
            :disabled="loading"
            class="mr-2"
        >
          {{ $t('Close') }}
        </v-btn>
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn-toggle class="mr-3">
                <v-btn
                  color="primary"
                  @click="createProduct(1)"
                  :loading="loading"
                  :disabled="loading"
                >
                  {{ $t('Create') }}
                </v-btn>
                <v-btn
                  color="primary"
                  :loading="loading"
                  :disabled="loading"
                  class="px-1"
                  v-bind="attrs" 
                  v-on="on"
                >
                  <v-icon color="white">{{ icons.mdiMenuDown }}</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
            <v-btn block @click="createProduct(0)">{{$t('To Draft')}}</v-btn>
          </v-menu>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ref, getCurrentInstance, computed, watch} from '@vue/composition-api'
import axios from "@axios"
import store from '@/store'
import {mdiMenuDown, mdiHelpCircle} from '@mdi/js'
import {cCodeToSymbol, marketCurrCode} from "@/plugins/helpers"
import {useRouter} from "@core/utils"

export default {
  props: {
    buttonText: {
      type: String,
      default: 'Create'
    },
  },
  setup(props, {refs}) {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const isDialogVisible = ref(false)
    const valid = ref(true)
    const form = ref(null)
    const loading = ref(false)
    const statusError = ref(false)
    const supplierLinkRules = [v => !!v || 'Supplier link required', v => validURLNotAmazon(v) || 'Invalid Supplier Link']
    const supplierLink = ref(null)
    const linkToAmazonRules = [v => !!v || 'Amazon link required', v => validURLAmazon(v) || 'Invalid Amazon Link']
    const linkToAmazon = ref(null)
    const dealPrice = ref(null)
    const currencyCode = ref('USD')
    const threePL = ref(null)
    const cog = ref(null)
    const bundle = ref(false)
    const priceRules = [v => !!v || 'Price required', v => v >= 1 || 'Minimum deal price $1']
    const thirdRules = [v => !!v || 'Third party logistics required', v => v >= 1 || 'Minimum 3PL amount $1']
    const cogRules = [v => !!v || 'Cost of goods required']
    const description = ref(null)
    const vendorNote = ref(null)
    const feesVal = ref(0)
    function validURLNotAmazon(str) {
      var expression = /^(?:(?:https?|ftp):\/\/)(?!.*amazon\.)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
      var pattern = new RegExp(expression);
      return !!pattern.test(str);
    }
    function validURLAmazon(str) {
      var expression = /(?:.amazon.*[/dp/]|$)([A-Z0-9]{10})/;
      var pattern = new RegExp(expression);
      if(typeof str === 'string') {
        let url = new URL(str)
        let hostname = url.host.replace('www.', '')
        currencyCode.value = marketCurrCode()[hostname]
      }
      return !!pattern.test(str);
    }
    axios.get('market-fees').then(res => {
      feesVal.value = res.data
    })
    const fees = computed(() => {
      return Math.trunc(dealPrice.value * feesVal.value) / 100 || null
    })
    watch(() => isDialogVisible.value, (newValue) => {
      //Close Create Modal
      if(!newValue) {
        form.value = linkToAmazon.value = supplierLink.value = cog.value = threePL.value = dealPrice.value = description.value = vendorNote.value = null
        bundle.value = false
      }
    })
    const createProduct = (status) => {
      if (!form.value.validate()) {
        return
      }
      loading.value = true
      let data = {
        product_url: linkToAmazon.value,
        source_link: supplierLink.value,
        cog: cog.value,
        bundle: bundle.value,
        logistics: threePL.value,
        price: dealPrice.value,
        description: description.value,
        vendor_note: vendorNote.value,
        status
      }
      axios.post('create-product', data)
          .then(res => {
            vm.$notify({
              group: 'deals',
              title: 'Product',
              type: 'success',
              text: vm.$t("Product successfully created"),
            })
            loading.value = false
            isDialogVisible.value = false
            router.push({name:'seller-product', params:{id:res.data.id}})
          })
          .catch(e => {
            let message = e.response?.status !== 500 && e.response?.data?.message?e.response.data.message:vm.$t("Product creation failed")
            vm.$notify({
              group: 'deals',
              title: 'Product Error',
              type: 'error',
              text: message,
            })
            loading.value = false
          })
    }

    return {
      currencyCode,
      cCodeToSymbol: cCodeToSymbol(),
      bundle,
      loading,
      isDialogVisible,
      supplierLinkRules,
      supplierLink,
      linkToAmazonRules,
      linkToAmazon,
      dealPrice,
      threePL,
      cog,
      description,
      vendorNote,
      priceRules,
      thirdRules,
      cogRules,
      fees,
      form,
      valid,
      createProduct,
      icons: {
        mdiMenuDown,
        mdiHelpCircle
      }
    }
  },
}
</script>
